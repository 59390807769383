$headerColor: #fafaff;
$mainTextColor: #221e41;
$secondaryTextColor: #757384;
$buttonColor: #00adff;
$highlightColor: #6937ff;
$highlightSecondaryColor: #221e41;
$highlightTextColor: #fff;
$highlightTextSecondary: #cdcbdf;

$singleMarginSize: 5%;
$singleBorderRadius: 100px;

$transitionWidth: 850px;


//tina
// #F5ECE7
// #E7DACD

//
//