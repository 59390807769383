@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
html {
  background-color: #fffdfc;
}

* {
  font-family: 'Open Sans', sans-serif;
  // font-family-headers: 'Arvo', 'Impact', 'Arial Black', serif;
  // font-family-monospace: monospace;
  box-sizing: border-box;
  margin: 0;
  padding: 0;


  .sections {
    width: 100%;
    position: relative;
    scroll-behavior: smooth;
    
    > * {
      width: 100vw;
      height: 85vh;
      padding: 0.4rem calc((100vw - 1000px) / 2);
    }
  }
}


h1 h2 h3 h4 h5 h6 {
  font-family: 'Arvo', 'Impact', 'Arial Black', serif;
  background-color: green;
}

.resume-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  overflow: scroll;

  .page {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}