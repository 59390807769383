@import "../../global.scss";
.skills {
    background-color: $highlightColor;
    width: 95%;
    height: auto;
    margin-left: $singleMarginSize;
    border-top-left-radius: $singleBorderRadius;
    padding: 0.5rem calc((90vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
        margin-right: 5%;
    }

    .section-title {
        color: $highlightTextColor;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        padding: 30px 0 70px 0;
    }
    .skills-title {        
        display: flex;
        justify-content: center;
        align-items: center;
        color: $highlightTextSecondary;
        padding: 10px 0 20px 0;
    }

    .skills-grid {
        margin-bottom: 80px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        .skills-item {

            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            height: 150px;
            width: 185px;
            padding: .25em;
            border: 1px solid #784bffec;
            border-left: none;
            border-top: none;

            * {
                color: $highlightTextSecondary;
                height: 70%;
                width: auto;

            }

            .skills-item-name {
                position: absolute;
                top: 1px;
                right: 1px;
                height: auto;
                margin: 0;
                background-color: rgba(34,30,65,0.2);
                color: $highlightTextColor;
                padding: 0.25em;
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 0.5px;
            }


            &:nth-child(4n) {
                border-right: none;
            }
            

        }
        
        .skills-item-bottomless {
            border-bottom: none;
        }
    } 


    @media screen and (max-width: $transitionWidth) {
        transition: 0.8 all ease;
        margin-left: 0;
        width: 100%;

        .skills-grid {
            grid-template-columns: repeat(2, 1fr);
            
            .skills-item {
                border: 1px solid #784BFF;
                border-top: none;

                &:nth-child(2n) {
                    border-right: none;
                }

                &:nth-child(2n + 1) {
                    border-left: none;
                }

            }

        }
    }
}