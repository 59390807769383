@import '../../global.scss';
.projects {
    width: 100%;
    margin-right: $singleMarginSize;
    margin-bottom: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: center;


    .project-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        height: 250px;
    }

    .tile-container {
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 100px;
    }

        .project-tile {
            background-color: rgb(240, 234, 234);
            display: flex;
            justify-content: center;
            border-radius: 0px;
            max-width: 860px; 
            height: 360px;
            overflow: hidden;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

            .text {
                padding: 3em;
                flex-basis: 40%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                h3 {
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: auto;
                }

                a {
                    align-self: center;
                    cursor: pointer;
                }

                button {
                    font-size: 17px;
                    border: 0px;
                    border-radius: 20px;
                    width: 10em;
                    height: 2.5em;
                    background-color: $highlightColor;
                    color: $highlightTextColor;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        background-color: #7547ff;
                    }
                }
            }

            .img-container {
                flex-basis: 60%;
                overflow:hidden;

                img {
                    height: 100%;
                    width: auto;
                }

                .primary-image {
                    z-index:1;
                }
            }

            &:hover {
                .primary-image {
                    display: none;
                }
            }
        }

    
    @media screen and (max-width: $transitionWidth) {
        transition: 0.8 all ease;
        padding: 0 1em;

        .project-tile {
            flex-direction: column;
            width: 90%;
            margin: 0 auto;
            max-width: 504px;
            height: 650px;
            
            .text {
                flex-basis: 40%;
                padding: 3em 3em 2em 3em;
            }

            .img-container {
                flex-basis: 60%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        


        }
    }
}