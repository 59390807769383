@import "../../global.scss";

.header {
    background-color: $headerColor;
    width: 95%;
    height: auto;
    margin-right: $singleMarginSize;
    border-bottom-right-radius: $singleBorderRadius;
    padding: 0.5rem calc((90vw - 1000px) / 2);
    padding-bottom: 60px;
    
    
    .header-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 130px 0 100px 0;
    }

    .photo {
        overflow: hidden;
        display: flex;
        padding: 1em;
        justify-content: center;
        align-items: center;
        grid-row: 1 / span 2;
        grid-column: 2;
    }

    .intro {
        padding: 0.5em;
        display: flex;
        align-items: flex-end;
        margin: 0;
        // border: 4px dotted lightsalmon;
        
        h1 {
            text-align: left;
            font-size: 60px;
            color: $mainTextColor;
        }
    }


    .description {
        padding: 0.5em;
        // border: 4px dotted lightsalmon;

        p {
            color: $secondaryTextColor;
            font-size: 20px;
            text-align: left;
            font-weight: 100;

        }
    }

    span {
        width: 95vw;
        display: inline;
        position: absolute;
        left: 0px;

        h5 {
            height: 50px;
            overflow: hidden;
            text-align: center;
            font-weight:300;
            color: $secondaryTextColor;

            &:before {   
                opacity: .30;

                content: "";
                display: inline-block;
                width: 60%;
                vertical-align: middle;
                border-top: 1px solid;
                margin: 0px 10px 0 0;
            }

            .icon {
                margin-left: 25px;
                height: 30px;
                width: auto;
                opacity: .80;
            }
        }
        
        a {
            color: $secondaryTextColor;
            &:visited {
                text-decoration: none;
            }
        }
    }
    img {
        max-width: 450px;
        height: auto;
    }

    @media screen and (max-width: $transitionWidth) {
        margin-right: 0;
        padding-bottom: 60px;

        .header-grid {
            display: flex;
            flex-direction: column;
            transition: 0.8 all ease;
            margin: 50px 0 20px 0;
            width: 100%;
        }
        .photo {
            display: flex;
            justify-content: center;
        }

        .intro {
             h1 {
                 text-align: center;
                 margin: 0 20%;
                 font-size: 40px;
             }
        }

        .description {
            p {
                margin: 0 15%;
            }
        }
        
        img {
            max-width: 350px;
            height: auto;
        }
        
        span {
            h5{
                &:before {
                    display:none;
                }
            }
        }
    }

    @media screen and(max-width: 600px){
        .intro {
            h1 {
                font-size: 36px;
                margin:0;
            }
        }
        .description {
            p{
                font-size: 17px;
            }
        }
    }

}