@import "../../global.scss";

.contact-section {
    color: #fff;
    height: auto;
    width: 100%;
    background-color: $highlightColor;
    padding: 0;

    h1 {
        margin: 30px 0 10px;
    }

    .contact {
        background-color: $highlightSecondaryColor;
        width: 95%;
        height: 100%;
        margin-right: $singleMarginSize;
        border-top-right-radius: $singleBorderRadius;
        padding: 0.5rem calc((90vw - 1000px) / 2);
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        * {
            margin-left: 5%;
        }

        h3 { 
            margin-top: 10px;
            margin-left: -100px;
        }

        p {
            margin-left: 5px;
        }
        .contact-form-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 400px;
            width: 400px;
            margin-bottom: 30px;
    
            form {
                width: 80%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
    
                input {
                    width: 375px;
                    height: 40px;
                    font-size: 17px;
                    padding: 0.1em;
                    &:focus {
                        outline: none;
                    }
                }
    
                textarea {
                    width: 375px;
                    height: 250px;
                    font-size: 17px;
                    padding: 0.1em;

                    &:focus {
                        outline: none;
                    }
                }
    
                button {
                    width: 150px;
                    height: 30px;
                    color: #fff;
                    background-color: $highlightColor;
                    border: none;
                    border-radius: 10px;
                    font-weight: 500;
                    cursor: pointer;
                }


                span {
                    color: #fff;
                    position: absolute;
                    bottom: -15px;
                }
            }
        }
    
        .info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 50px;
            margin: 50px 0;
    
            .info-group {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 30px;
                width: 100px;
            }
    
            a {
                text-decoration: none;
                color: #fff;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        @media screen and (max-width: $transitionWidth) {
            transition: 0.8 all ease;
            margin-right: 0;
            width: 100%;
        }
    }
}
